<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="">
    <b-card>
      <b-row>
        <table class="table table-sm mb-2">
          <thead>
            <tr>
              <th scope="col">
                Project Add
              </th>
            </tr>
          </thead>
        </table>
      </b-row>

      <div>
        <validation-observer ref="addProjectRules">
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Project Name*">
                  <validation-provider name="Project Name" #default="{ errors }" rules="required">
                    <b-form-input id="name" v-model="projectData.name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Select a Client">
                  <v-select v-model="selectedClient" label="name" :options="paginated" class="small-v-select" :clearable="false" :filterable="false" @open="onOpen" @close="onClose" @search="(query) => (search = query)">
                    <template #list-footer>
                      <li v-show="hasNextPage" ref="load" class="loader">
                        Loading more options...
                      </li>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Starting Date">
                  <flat-pickr
                    v-model="projectData.startDate"
                    class="form-control"
                    style="background-color: white;"
                    :config="{
                      dateFormat: 'd-m-Y',
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Deadline">
                  <flat-pickr
                    v-model="projectData.endDate"
                    class="form-control"
                    style="background-color: white;"
                    :config="{
                      dateFormat: 'd-m-Y',
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Team">
                 <v-select v-model="projectData.team" :options="['Web','VBA']"></v-select>
                </b-form-group>
              </b-col>

            </b-row>

            <profile-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
            <b-row>
              <b-col cols="12">
                <b-img-lazy thumbnail fluid v-bind="mainProps" style="width: 260px; height: 154px;" ref="refPreviewEl" :src="companiesURL + projectData.logo" />

                <b-button style="width: 260px;" size="sm" @click="$refs.refInputEl.click()" block variant="primary">
                  Upload
                </b-button>
              </b-col>

              <input ref="refInputEl" type="file" class="d-none" accept="image/*" @input="imageSelected" />

              <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
              </b-col>
            </b-row>

            <b-col cols="12" class="mt-5">
              <b-button type="submit" @click.prevent="handleOk" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
            </b-col>
          </b-form>
        </validation-observer>
        <!-- </b-modal> -->
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BImg } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import axiosIns from '@/libs/axios';
import router from '@/router';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BImg,

    ProfileCropper,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      companiesURL: store.state.app.companiesURL,
      required,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      loading: true,
      selectedImage: null,

      clients: [],
      observer: null,
      limit: 10,
      search: '',
      selectedClient: null,
      percentCompleted: 0,
      projectData: {
        name: null,
        client: null,
        startDate: null,
        endDate:null,
        team: null,
        logo: 'placeholder.jpg',
        taskList: [],
      },
    };
  },

  watch: {
    selectedClient(newVal, oldVal) {
      this.projectData.client = newVal ? newVal : null;
    },
  },

  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.limit = 10;
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit(component) {
      this.checkValidity(component);
    },

    checkValidity(component) {
      this.$refs.addProjectRules.validate().then((success) => {
        if (success) {
          this.completeSave();

          // this.$nextTick(() => {
          //   this.$bvModal.hide('modal-create-project');
          // });
        } else {
          return;
        }
      });
    },

    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;
      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.$emit('update:formShow', true);
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('cropCompanyPhoto', formData, config)
          .then((response) => {
            this.projectData.logo = response.data;
            this.selectedImage = null;
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    completeSave() {
      this.loading = true;
      store
        .dispatch('project/saveProject', this.projectData)
        .then((res) => {
          (this.loading = false),
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been created',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

          router.push({
            name: 'projects',
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getProjectClients() {
      store
        .dispatch('project/getProjectClients')
        .then((res) => {
          this.clients = res.data;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          this.$swal({
            title: 'Error!',
            text: 'Clients list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },

  created() {
    this.getProjectClients();
  },
  computed: {
    filtered() {
      const search = this.search || '';
      return this.clients.filter((contact) => (contact.name || '').toLowerCase().includes(search.toLowerCase()));
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.brand-logo img {
  max-width: 80%;
  margin-left: 5%;
  margin-top: 15%;
}
</style>
