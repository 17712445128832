var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-fade":""}},[_c('b-card',[_c('b-row',[_c('table',{staticClass:"table table-sm mb-2"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Project Add ")])])])])]),_c('div',[_c('validation-observer',{ref:"addProjectRules"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Project Name*"}},[_c('validation-provider',{attrs:{"name":"Project Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.projectData.name),callback:function ($$v) {_vm.$set(_vm.projectData, "name", $$v)},expression:"projectData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Select a Client"}},[_c('v-select',{staticClass:"small-v-select",attrs:{"label":"name","options":_vm.paginated,"clearable":false,"filterable":false},on:{"open":_vm.onOpen,"close":_vm.onClose,"search":function (query) { return (_vm.search = query); }},scopedSlots:_vm._u([{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextPage),expression:"hasNextPage"}],ref:"load",staticClass:"loader"},[_vm._v(" Loading more options... ")])]},proxy:true}]),model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Starting Date"}},[_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"white"},attrs:{"config":{
                    dateFormat: 'd-m-Y',
                    locale: {
                      firstDayOfWeek: 1,
                    },
                  }},model:{value:(_vm.projectData.startDate),callback:function ($$v) {_vm.$set(_vm.projectData, "startDate", $$v)},expression:"projectData.startDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Deadline"}},[_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"white"},attrs:{"config":{
                    dateFormat: 'd-m-Y',
                    locale: {
                      firstDayOfWeek: 1,
                    },
                  }},model:{value:(_vm.projectData.endDate),callback:function ($$v) {_vm.$set(_vm.projectData, "endDate", $$v)},expression:"projectData.endDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('b-form-group',{attrs:{"label":"Team"}},[_c('v-select',{attrs:{"options":['Web','VBA']},model:{value:(_vm.projectData.team),callback:function ($$v) {_vm.$set(_vm.projectData, "team", $$v)},expression:"projectData.team"}})],1)],1)],1),(_vm.selectedImage)?_c('profile-cropper',{attrs:{"image":_vm.selectedImage},on:{"cropped":_vm.imageCropped}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-img-lazy',_vm._b({ref:"refPreviewEl",staticStyle:{"width":"260px","height":"154px"},attrs:{"thumbnail":"","fluid":"","src":_vm.companiesURL + _vm.projectData.logo}},'b-img-lazy',_vm.mainProps,false)),_c('b-button',{staticStyle:{"width":"260px"},attrs:{"size":"sm","block":"","variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_vm._v(" Upload ")])],1),_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"input":_vm.imageSelected}}),(_vm.percentCompleted != 0 && _vm.percentCompleted != 100)?_c('b-col',{staticClass:"mt-2"},[_c('b-progress',{key:"info",staticClass:"'progress-bar-info'",attrs:{"animated":"","value":_vm.percentCompleted,"show-progress":"","variant":"dark"}})],1):_vm._e()],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }